<template>
  <div>
    <a-modal
      v-model="visible"
      :confirmLoading="loading"
      :destroyOnClose="true"
      :maskClosable="false"
      centered
      @cancel="cancel"
      @ok="confirm"
    >
      <div slot="title">{{ form.id ? $t("编辑往来单位") : $t("新增往来单位") }}</div>
      <div>
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
          <a-form-model-item prop="number" :label="$t('往来单位编号')">
            <a-input v-model="form.number" allowClear />
          </a-form-model-item>
          <a-form-model-item prop="name" :label="$t('往来单位名称')">
            <a-input v-model="form.name" />
          </a-form-model-item>
          <a-form-model-item prop="type" :label="$t('类型')">
            <a-select v-model="form.type" allowClear style="width: 100%">
              <a-select-option key="client" value="client">{{ $t("客户") }}</a-select-option>
              <a-select-option key="supplier" value="supplier">{{ $t("供应商") }}</a-select-option>
              <a-select-option key="plant" value="plant">{{ $t("工厂") }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="contacts" :label="$t('联系人')">
            <a-input v-model="form.contacts" />
          </a-form-model-item>
          <a-form-model-item prop="phone" :label="$t('电话')">
            <a-input v-model="form.phone" />
          </a-form-model-item>
          <a-form-model-item prop="email" :label="$t('邮箱')">
            <a-input v-model="form.email" />
          </a-form-model-item>
          <a-form-model-item prop="address" :label="$t('地址')">
            <a-input v-model="form.address" />
          </a-form-model-item>
          <a-form-model-item prop="remark" :label="$t('备注')">
            <a-textarea v-model="form.remark" />
          </a-form-model-item>
          <a-form-model-item prop="is_active" :label="$t('状态')">
            <a-select v-model="form.is_active" style="width: 100%">
              <a-select-option :value="true">{{ $t("激活") }}</a-select-option>
              <a-select-option :value="false">{{ $t("冻结") }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { counterpartyCreate, counterpartyUpdate, getCounterpartyNumber } from "@/api/data";
import { rules } from "./rules";

export default {
  props: ["visible", "item"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      rules,
      loading: false,
      form: {},
    };
  },
  methods: {
    confirm() {
      this.form.id ? this.update() : this.create();
    },
    create() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let formData = { ...this.form };

          this.loading = true;
          counterpartyCreate(formData)
            .then((data) => {
              this.$message.success(this.$t("新增成功"));
              this.$parent.items = this.$functions.insertItem(this.$parent.items, data);
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    update() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let formData = { ...this.form };

          this.loading = true;
          counterpartyUpdate(formData)
            .then((data) => {
              this.$message.success(this.$t("修改成功"));
              this.$parent.items = this.$functions.replaceItem(this.$parent.items, data);
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      this.$refs.form.resetFields();
    },
  },
  watch: {
    visible(status) {
      if (status) {
        if (this.item.id) {
          this.form = { ...this.item };
        } else {
          getCounterpartyNumber().then((data) => {
            this.form = { is_active: true, number: data.number };
          });
        }
      }
    },
  },
};
</script>

<style scoped></style>
